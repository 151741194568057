(function($){

    if(typeof acf === 'undefined')
        return;

    /**
     * Add automatic scroll to form successfully submited
     * Work only on ACFE form whithout redirection after submission
     */
    acf.addAction('acfe/form/submit/success', function(){

        if ( $( '.acfe-form-success' ).length ) {
            $( 'html,body' ).scrollTop( $( '.acfe-form-success' ).offset().top - 100 );
        }
    });
    
})(jQuery);